import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
export default function Header() {

    const [showAboutModal, setShowAboutModal] = useState(false);
    const [showRulesModal, setShowRulesModal] = useState(false);

    const openAboutModal = () => {
        setShowAboutModal(!showAboutModal)
        setShowRulesModal(false)
        document.body.classList.add('modal-open')
    }

    const openRulesModal = () => {
        setShowRulesModal(!showRulesModal)
        setShowAboutModal(false)
        document.body.classList.add('modal-open')
    }
  
    const closeModal = () => {
        setShowAboutModal(false)
        setShowRulesModal(false)
        document.body.classList.remove('modal-open')
    }

    useEffect(() => {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          closeModal();
        }
      };
  
      const handleClickOutside = (event) => {
        if (
          event.target.classList.contains('header') &&
          (showAboutModal || showRulesModal)
        ) {
          closeModal();
        }
      };
  
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('click', handleClickOutside);
  
      return () => {
        document.removeEventListener('keydown', handleEscape);
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showAboutModal,showRulesModal]);

    return (
        <>
        <header className="header">
            <Link className='' to="/"><div className='title'>VocabuZest</div></Link>
            <div className='space-x-2'><a className='nav-link cursor-pointer' onClick={openAboutModal}>About</a><span className='text-neutral-300 font-extralight'> | </span><a className='nav-link cursor-pointer' onClick={openRulesModal}>Rules</a></div>
        </header>
        
        {showRulesModal && (
        <div className="fixed flex items-center justify-center z-50 w-full modal px-3">
        <div className="relative w-full max-w-2xl h-full">
                <div className="relative rounded-t-2xl h-full modal-bg">
                    <div className="flex items-start justify-between p-4 rounded-t border-b-2" style={{borderColor: '#85AD9A'}}>
                        <h3 className="text-xl font-semibold text-neutral-800">Rules</h3>
                        <button type="button" className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center text-neutral-800" data-modal-hide="defaultModal" onClick={closeModal}>
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-5 pb-44 space-y-6 text-justify h-full overflow-y-auto">
                        <p className="text-sm leading-relaxed text-neutral-900">
                            <span className='font-semibold'>VocabuZest</span> - Where Vocabulary Meets Zest!
                        </p>
                        <div>
                        <p className="text-sm leading-relaxed text-neutral-700">
                            &bull; The word must contain at least 4 letters.
                        </p>
                        <img className='w-full' src='./images/rule1.png'/>
                        </div>
                        <hr style={{borderColor: '#85AD9A'}}/>
                        <div>
                        <p className="text-sm leading-relaxed text-neutral-700">
                        &bull; The word must contain the center letter.
                        </p>
                        <img className='w-2/3 mx-auto' src='./images/rule2.png'/>
                        </div>
                        <hr style={{borderColor: '#85AD9A'}}/>
                        <p className="text-sm leading-relaxed text-neutral-600">
                            That's it, Enjoy!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        )}

        {showAboutModal && (
        <div className="fixed flex items-center justify-center z-50 w-full modal px-3">
        <div className="relative w-full max-w-2xl h-full">
                <div className="relative rounded-t-2xl h-full modal-bg">
                    <div className="flex items-start justify-between p-4 rounded-t border-b-2" style={{borderColor: '#85AD9A'}}>
                        <h3 className="text-xl font-semibold text-neutral-800">About</h3>
                        <button type="button" className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center text-neutral-800" data-modal-hide="defaultModal" onClick={closeModal}>
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-5 pb-44 space-y-6 text-justify h-full overflow-y-auto">
                        <p className="text-sm leading-relaxed text-neutral-900">
                            <span className='font-semibold'>VocabuZest</span> - Where Vocabulary Meets Zest!
                        </p>
                        <p className="text-sm leading-relaxed text-neutral-700">
                            We believe that words have the power to inspire, educate, and entertain. We have created an exciting web game that challenges your vocabulary skills and unleashes your creativity. Are you ready to dive into the world of words?
                            <br/><br/>
                            How does Vocabuzest work? It's simple. Every day at 12 am, we provide you with seven random letters. Your task is to create as many words as possible using those letters but the word must have the center letter. With new letters every day, you'll always have a fresh challenge waiting for you.
                            <br/><br/>
                            It's about expanding your vocabulary and exploring the endless possibilities of language. As you play, you'll discover new words, improve your linguistic skills, and gain a deeper appreciation for the beauty and versatility of the English language.
                        </p>
                        <hr style={{borderColor: '#85AD9A'}}/>
                        <p className="text-sm leading-relaxed text-neutral-700">
                          <span className='font-semibold'>If you know Urdu</span> we highly encourage you to play the Urdu version too. In today's world, traditional Urdu language skills are becoming less prevalent. Our game not only entertains but also acts as a tool to enhance your language skills and help correct your spoken language. Maybe play it with elder people and see them making words that we don't even know exist in Urdu dictionary.
                        </p>
                        <hr style={{borderColor: '#85AD9A'}}/>
                        <p className="text-xs leading-relaxed text-neutral-600">
                            The inspiration for the game was Spelling Bee by NYTimes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        )}
        </>
    )
  }
  