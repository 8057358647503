import { Link } from 'react-router-dom';
import Layout from '../components/Layout';

function Home() {

  const date = new Date();
  let currentDay= String(date.getDate()).padStart(2, '0');
//   let currentMonth = String(date.getMonth()+1).padStart(2,"0");
  let currentYear = date.getFullYear();
//   let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const currentMonthIndex = date.getMonth();
  const currentMonthName = ['January','February','March','April','May','June','July','August','September','October','November','December'][currentMonthIndex];
  const today = (currentMonthName+" "+currentDay+", "+currentYear);

  return (
    <Layout>
      <main className='splash bg-main'>
        <div className='mx-auto max-w-screen-sm justify-center items-center align-middle text-center p-10'>
            <div className='text-neutral-300 mb-10'>{today}</div>
            <div className='text-white text-2xl font-semibold' style={{lineHeight: 1.75, letterSpacing:2}}>In which language do you want to play ?</div>
            <div className='w-full flex flex-row space-x-3 mt-5'>
              <Link className='flex-1 play-btn' to="/english">English</Link >
              <Link className='flex-1 play-btn' to="/urdu">اردو</Link >
            </div>
        </div>
      </main>
    </Layout>
  );
}

export default Home;
