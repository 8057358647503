import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    // <html lang="en">
    //   <body>
    <>
        <Header />
        {children}
        <Footer />
    </>
    //   </body>
    // </html>
  );
};

export default Layout;