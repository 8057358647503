import "../styles/puzzle.css";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { SlRefresh } from "react-icons/sl";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import axios from "axios";
import Sentry from "react-activity/dist/Sentry";
import "react-activity/dist/Sentry.css";

function English() {
  const [allletters, setallletters] = useState("");
  const [mainletter, setmainletter] = useState("");
  const [totalwords, settotalwords] = useState("");
  const [todaysdate, settodaysdate] = useState("");
  const [word, setword] = useState("");
  const [todaysresult, settodaysresult] = useState("");
  const [todaysresultarr, settodaysresultarr] = useState("");
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(true);
  const [verifyloading, setverifyloading] = useState(false);
  const [showwords, setshowwords] = useState(false);
  const [play, setplay] = useState(false);
  const letters = allletters.replace(mainletter, "").split("");

  const date = new Date();
  const utcdatex = Date.now();
  const utcdate = utcdatex + 5 * 60 * 60 * 1000;

  console.log("date: " + utcdate);
  const fetchresult = () => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();
    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentMonthIndex = date.getMonth();
    const currentMonthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][currentMonthIndex];
    settodaysdate(currentMonthName + " " + currentDay + ", " + currentYear);

    const storedRecordData = getDataWithExpiration(currentDate);
    settodaysresult(storedRecordData != null ? storedRecordData.replaceAll(",", ", ") : storedRecordData);
    if (storedRecordData !== null) {
      settodaysresultarr(storedRecordData.split(","));
    }
  };

  const getpuzzle = () => {
    axios
      .get("https://vocabuzest.developmint.xyz/api/getpuzzle")
      .then((response) => {
        let result = JSON.parse(response.data)[0];
        console.log(result);
        setallletters(result.letters);
        setmainletter(result.mainletter);
        settotalwords(result.totalwords);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  useEffect(() => {
    getpuzzle();
    fetchresult();
  }, []);

  const removeletter = () => {
    if (word !== "") {
      setword(word.slice(0, -1));
    }
  };

  const updateDataWithExpiration = (key, value, expirationHours) => {
    const existingValue = localStorage.getItem(key);
    if (existingValue) {
      const updatedValue = `${existingValue}, ${value}`;
      const uniqueArray = [...new Set(updatedValue.split(", "))];
      localStorage.setItem(key, uniqueArray.join(","));
    } else {
      localStorage.setItem(key, value);
    }

    // Set the expiration time
    const expirationMs = expirationHours * 60 * 60 * 1000;
    const expirationTime = utcdate + expirationMs;
    localStorage.setItem(`${key}_expiration`, expirationTime);
  };

  const getDataWithExpiration = (key) => {
    const expirationTime = localStorage.getItem(`${key}_expiration`);
    if (expirationTime && utcdate > expirationTime) {
      // If the expiration time has passed, remove the data
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}_expiration`);
      return null;
    }

    return localStorage.getItem(key);
  };

  const verifyword = () => {
    if (word !== "") {
      if (word.length > 3) {
        if (word.indexOf(mainletter) > -1) {
          setverifyloading(true);
          const wordData = {
            value: word,
          };
          axios
            .post("https://vocabuzest.developmint.xyz/api/verifypuzzle", wordData)
            .then((response) => {
              let result = JSON.parse(response.data)[0];
              console.log("Reactjs: " + utcdate);
              console.log("Nextjs: " + result.date);
              if (result.found === 1) {
                setword("");
                const date = result.date;
                if (todaysresultarr.includes(word)) {
                  seterror("Already found");
                  setTimeout(function () {
                    seterror("");
                  }, 2000);
                } else {
                  updateDataWithExpiration(date, word, 24);
                  fetchresult();
                  seterror("Word found +1");
                  setTimeout(function () {
                    seterror("");
                  }, 2000);
                }
              } else if (result.found === 0) {
                setword("");
                seterror("Not in word list");
                setTimeout(function () {
                  seterror("");
                }, 2000);
              }
              setverifyloading(false);
            })
            .catch((error) => {
              seterror(error);
              setTimeout(function () {
                seterror("");
              }, 2000);
              setverifyloading(false);
            });
        } else {
          setword("");
          seterror("Missing center letter");
          setTimeout(function () {
            seterror("");
          }, 2000);
        }
      } else {
        seterror("Too short");
        setTimeout(function () {
          seterror("");
        }, 2000);
      }
    }
  };

  // localStorage.clear();

  return (
    <Layout>
      {play === false ? (
        <main className="splash bg-main">
          <div className="mx-auto max-w-screen-sm justify-center items-center align-middle text-center p-10">
            <div className="text-neutral-300 mb-10">{todaysdate}</div>
            <div className="text-white text-2xl font-semibold" style={{ lineHeight: 1.75, letterSpacing: 2 }}>
              How many words can you make with 7 letters?
            </div>
            <button onClick={() => setplay(true)} className="play-btn">
              {todaysresultarr.length > 0 ? "Keep Playing" : "Start Now"}
            </button>
            {todaysresultarr.length > 0 ? (
              <div className="mt-5 text text-neutral-400">
                You have found{" "}
                <b>
                  {todaysresultarr.length === totalwords ? "all" : ""} {todaysresultarr.length > 1 ? todaysresultarr.length + " words" : todaysresultarr.length + " word"}
                </b>
              </div>
            ) : (
              ""
            )}
          </div>
        </main>
      ) : (
        <main className="bg-white">
          {loading === true ? (
            <div className="w-full flex h-full py-20 justify-center text-center mx-auto max-w-screen-sm">Loading...</div>
          ) : (
            <div className="puzzle mx-auto">
              {todaysresultarr.length === totalwords ? (
                <div className="success-box">Great Job! You have an amazing vocabulary</div>
              ) : (
                <>
                  <div className="word-box">{word}</div>
                  <div className={`error-box ${error === "" ? "" : "show"}`}>
                    <span>{error}</span>
                  </div>
                </>
              )}
              <div className="result-box">
                <div className="flex flex-row justify-between items-center cursor-pointer" onClick={() => setshowwords(!showwords)}>
                  <div className="title">
                    You have found {todaysresultarr.length === totalwords ? "all" : ""} {todaysresultarr.length > 1 ? todaysresultarr.length + " words" : todaysresultarr.length + " word"}
                  </div>
                  <div>
                    <span>{showwords === false ? <FaChevronDown size={12} /> : <FaChevronUp size={12} />}</span>
                  </div>
                </div>
                {todaysresultarr.length > 0 ? (
                  <progress className="progress" value={(todaysresultarr.length / totalwords) * 100} max="100">
                    {" "}
                    32%{" "}
                  </progress>
                ) : (
                  ""
                )}
                <div className={`found-words ${showwords === false ? "hidden" : "show"} `}>
                  {todaysresult}
                  <div className="text-neutral-400 font-light text-sm mt-10">{totalwords} possible words today</div>
                </div>
              </div>
              <div className="letter-box">
                <div className="hex">
                  <svg
                    className="hex-cell center"
                    viewBox="0 0 120 103.92304845413263"
                    onClick={() => {
                      setword((prevLetters) => prevLetters + mainletter);
                    }}
                  >
                    <polygon className="cell-fill" points="0,51.96152422706631 30,0 90,0 120,51.96152422706631 90,103.92304845413263 30,103.92304845413263" stroke="white" strokeWidth="7.5"></polygon>
                    <text className="cell-letter" x="50%" y="50%" dy="0.35em">
                      {mainletter}
                    </text>
                  </svg>
                  {letters.map((hex, index) => (
                    <svg
                      className="hex-cell outer"
                      viewBox="0 0 120 103.92304845413263"
                      key={index}
                      onClick={() => {
                        setword((prevLetters) => prevLetters + hex);
                      }}
                    >
                      <polygon className="cell-fill" points="0,51.96152422706631 30,0 90,0 120,51.96152422706631 90,103.92304845413263 30,103.92304845413263" stroke="white" strokeWidth="7.5"></polygon>
                      <text className="cell-letter" x="50%" y="50%" dy="0.35em">
                        {hex}
                      </text>
                    </svg>
                  ))}
                </div>
              </div>
              {todaysresultarr.length === totalwords ? (
                ""
              ) : (
                <div className={`action-btns ${word === "" ? "opacity-60" : ""}`}>
                  <button className={`btn-delete`} disabled={verifyloading} onClick={() => removeletter()}>
                    Delete
                  </button>
                  {/* <SlRefresh className='btn-reset'/> */}
                  {verifyloading === true ? <Sentry animating={verifyloading} className="mx-3" /> : <div className="mx-7"></div>}
                  <button className={`btn-submit`} disabled={verifyloading} onClick={() => verifyword()}>
                    Submit
                  </button>
                </div>
              )}
            </div>
          )}
        </main>
      )}
    </Layout>
  );
}

export default English;
