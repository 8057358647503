import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import English from './pages/English';
import Urdu from './pages/Urdu';

function App() {
  return (
    <BrowserRouter>
    <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          <Route path="/" index element={<Home />} />
          <Route path="/english" element={<English />} />
          <Route path="/urdu" element={<Urdu />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        {/* </Route> */}
      </Routes>
      </BrowserRouter>
  );
}

export default App;
